import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import './home.css';

const Intro = lazy(() => import('./intro/Intro'));
const Banner = lazy(() => import('./banner/Banner'));
const EnergizandoFuturo = lazy(() => import('./energizandoFuturo/EnergizandoFuturo'));
const PropriaEnergia = lazy(() => import('./propriaEnergia/PropriaEnergia'));
const EnergiaRenovavel = lazy(() => import('./energiaRenovavel/EnergiaRenovavel'));
const Invista = lazy(() => import('./invista/Invista'));
const Footer = lazy(() => import('../components/footer/Footer'));
const Navbar = lazy(() => import('../components/navbar/Navbar'));
const Sobrenos = lazy(() => import('./sobrenos/Sobrenos'));

const Home = () => {
  return (
    <div className='home'>
      <Helmet>
        <title>Energizando o Futuro | Soluções Elétricas Sustentáveis</title>
        <meta name="description" content="Especialistas em energia fotovoltaica, projetos elétricos industriais, comerciais e residenciais." />
        <meta name="keywords" content="energia solar, sustentabilidade, eletricidade industrial, energia renovável" />
        <meta property="og:title" content="Energizando o Futuro | Soluções Elétricas Sustentáveis" />
        <meta property="og:description" content="Especialistas em desenvolvimento de projetos de energia fotovoltaica, elétricos industriais e residenciais." />
        <meta property="og:url" content="https://voltmais.com.br" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://voltmais.com.br" />
      </Helmet>

      <Suspense fallback={
            <div className="containerSun">
            <div className="cloud front">
              <span className="left-front"></span>
              <span className="right-front"></span>
            </div>
            <span className="sunshine"></span>
            <span className="sun"></span>
            <div className="cloud back">
              <span className="left-back"></span>
              <span className="right-back"></span>
            </div>
          </div>
      }>

        <Navbar />
        <Intro />
        <Banner />
        <EnergizandoFuturo />
        <PropriaEnergia />
        <EnergiaRenovavel />
        <Invista />
        <Sobrenos />
        <Footer />
      </Suspense>
    </div>
  );
}

export default Home;
