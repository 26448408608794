import React, { useEffect } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="relative min-h-screen flex flex-col bg-[#0055fe]">
      <Navbar />
      <div className="flex-grow flex flex-col justify-center items-center p-6 md:p-12 bg-[#034cdf81] mx-[2.5%] mt-32 rounded-t-3xl">
      <h1 className="text-3xl font-bold mb-8 text-white">Política de Privacidade – Voltmais Soluções Elétricas Sustentáveis</h1>

      <p className="text-white mb-6">
        Na Voltmais Soluções Elétricas Sustentáveis, a sua privacidade e a proteção dos seus dados pessoais são de extrema importância. Esta Política de Privacidade descreve como coletamos, utilizamos, armazenamos e compartilhamos suas informações, em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018 - LGPD) e demais legislações aplicáveis.
      </p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">1. Coleta de Dados Pessoais</h2>
        <p className="text-white mb-4">
          Coletamos seus dados pessoais de forma transparente e apenas para finalidades legítimas. Os dados coletados podem incluir:
        </p>
        <ul className="list-disc pl-6 text-white space-y-2">
          <li><span className="font-semibold">Dados de Identificação:</span> Nome, CPF, RG, e outros dados necessários para identificação pessoal.</li>
          <li><span className="font-semibold">Dados de Contato:</span> Endereço, e-mail, número de telefone.</li>
          <li><span className="font-semibold">Dados de Uso:</span> Informações sobre como você utiliza nossos serviços e interage com nossas plataformas digitais.</li>
          <li><span className="font-semibold">Dados Financeiros:</span> Informações necessárias para pagamento, como dados de cartão de crédito ou débito, em conformidade com as regras de segurança de dados.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">2. Finalidade do Tratamento de Dados</h2>
        <p className="text-white mb-4">Os dados pessoais que coletamos são utilizados para os seguintes fins:</p>
        <ul className="list-disc pl-6 text-white space-y-2">
          <li>Fornecimento de produtos e serviços solicitados.</li>
          <li>Processamento de pagamentos.</li>
          <li>Atendimento ao cliente e suporte técnico.</li>
          <li>Envio de comunicações, como boletins informativos, ofertas e informações sobre nossos produtos e serviços (com seu consentimento).</li>
          <li>Cumprimento de obrigações legais e regulatórias.</li>
          <li>Melhoria de nossos serviços e personalização de sua experiência com a Voltmais.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">3. Compartilhamento de Dados</h2>
        <p className="text-white mb-4">Seus dados pessoais poderão ser compartilhados com terceiros nas seguintes circunstâncias:</p>
        <ul className="list-disc pl-6 text-white space-y-2">
          <li><span className="font-semibold">Fornecedores e Parceiros:</span> Para a execução dos serviços contratados, podemos compartilhar informações com fornecedores e parceiros que auxiliam na prestação de nossos serviços.</li>
          <li><span className="font-semibold">Obrigação Legal:</span> Quando exigido por lei ou por autoridades regulatórias.</li>
          <li><span className="font-semibold">Consentimento:</span> Em caso de compartilhamento com finalidades que não estejam previstas nesta política, solicitaremos o seu consentimento prévio.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">4. Segurança dos Dados</h2>
        <p className="text-white">
          A Voltmais adota medidas técnicas e organizacionais apropriadas para proteger seus dados pessoais contra acesso não autorizado, alteração, divulgação ou destruição. Utilizamos tecnologias de criptografia e práticas de segurança avançadas para garantir a integridade e a confidencialidade de suas informações.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">5. Seus Direitos</h2>
        <p className="text-white mb-4">Você, como titular dos dados, tem direitos garantidos pela LGPD, incluindo:</p>
        <ul className="list-disc pl-6 text-white space-y-2">
          <li>Direito de acesso aos seus dados pessoais.</li>
          <li>Direito à correção de dados incompletos, inexatos ou desatualizados.</li>
          <li>Direito à anonimização, bloqueio ou eliminação de dados desnecessários ou excessivos.</li>
          <li>Direito à portabilidade dos dados.</li>
          <li>Direito à eliminação de dados pessoais tratados com o consentimento.</li>
          <li>Direito de informação sobre as entidades com as quais compartilhamos seus dados.</li>
          <li>Direito de revogar o consentimento, a qualquer momento, mediante solicitação.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">6. Retenção de Dados</h2>
        <p className="text-white">
          Os dados pessoais serão armazenados apenas pelo tempo necessário para cumprir com as finalidades para as quais foram coletados, ou conforme exigido por obrigações legais ou regulatórias.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">7. Cookies e Tecnologias Similares</h2>
        <p className="text-white">
          Utilizamos cookies e tecnologias similares para melhorar sua experiência em nosso site e para coletar informações sobre o uso de nossa plataforma. Você pode controlar o uso de cookies através das configurações do seu navegador.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">8. Alterações na Política de Privacidade</h2>
        <p className="text-white">
          Esta Política de Privacidade pode ser atualizada de tempos em tempos para refletir mudanças em nossos processos ou para atender às alterações legais. Recomendamos que você revise esta política periodicamente.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">9. Contato</h2>
        <p className="text-white">
          Se você tiver dúvidas, solicitações ou reclamações relacionadas ao tratamento de seus dados pessoais, entre em contato conosco através do e-mail:
          <a href="mailto:privacidade@voltmais.com.br" className="text-[#48c448] hover:text-[#53e053] ml-1">
            privacidade@voltmais.com.br
          </a>
        </p>
      </section>

      <p className="text-sm text-white mt-8">
        Última atualização: 24 de outubro de 2024
      </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
