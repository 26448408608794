import React, { useEffect } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import volt from '../../../src/assets/voltmais_completo.webp';
import nuvem from '../../../src/assets/nuvem.webp';
import placa_solar_campo from '../../../src/assets/placa_solar_campo.webp';
import julio_cesar from '../../../src/assets/julio_cesar.webp';
import clean from '../../../src/assets/clean.webp';
import { Link, useLocation } from 'react-router-dom';
import WhatsAppButton from '../../components/WhatsAppButton/WhatsAppButton';
import FadeInSection from '../../components/FadeInSection/FadeInSection';

const AboutUs: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const scrollToJulio = (e: any) => {
    e.preventDefault();
    const julioSection = document.getElementById('sobre-julio');

    if (julioSection === null) {
      return;
    }

    const yOffset = -100;
    const rect = julioSection.getBoundingClientRect();
    const y = rect.top + window.scrollY + yOffset;

    window.scrollTo({
      top: y,
      behavior: 'smooth'
    });
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#0055fe] overflow-x-hidden">
      <Navbar />
      <div className="flex-grow flex flex-col justify-center items-center p-6 md:p-12 bg-[#034cdf81] mx-[2.5%] mt-32 rounded-t-3xl">
        <div className="container mx-2 px-1 md:px-1">
          <FadeInSection>
            <div className='flex flex-col md:flex-row w-full mt-10'>
              <h2 className="w-full md:w-[60%] text-5xl md:text-6xl xl:text-7xl font-normal text-start text-[#4ed44f] my-6 md:ml-10 AcuminPro-font leading-tight">
                SOBRE A<br />
                <img src={volt} alt="VoltMAIS" className="h-14 md:h-20 xl:h-18" />
              </h2>
              <div className="w-full md:w-[40%] flex flex-col md:flex-row items-center">
                <div className="hidden md:block h-[1px] md:h-[325px] w-full md:w-[1px] bg-[#a6b3d1] my-4 md:my-0 md:mx-4"></div>
                <div className='flex flex-col'>
                  <h4 className="mt-20 md:mt-10 sm:mt-10 text-[20px] md:text-[24px] text-[#fff] roboto-flex-font text-justify">Na VoltMAIS, somos especialistas em soluções de energia sustentável, há mais de 22 anos no mercado e pioneira em projetos de energia solar fotovoltaica além de sistemas elétricos industriais, comerciais e residenciais. Combinamos inovação e expertise técnica para entregar resultados que superam as expectativas de nossos clientes.</h4>
                  <Link to="/" state={{ scrollToTop: true }}>
                    <div className='flex justify-center items-center mt-5'>
                      <button
                        onClick={scrollToJulio}
                        className="relative px-8 py-2 rounded-full bg-white isolation-auto border-2 border-[#1060fe] text-[#1060fe]
                        before:absolute before:w-full before:transition-all
                        before:duration-700 before:hover:w-full before:-right-full
                        before:hover:right-0 before:rounded-full  before:bg-[#54ff00] before:-z-10
                        before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-700 robot-flex-font z-50"
                      >
                        Saber mais
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-[90%] mx-auto h-[1px] bg-[#90ff3c] mt-[28vh] rounded-3xl"></div>
          </FadeInSection>
          <div className="w-full h-[50vh] md:h-[70vh] relative mt-10 z-40">
            <img
              src={placa_solar_campo}
              alt="Campo de placas solares"
              className="w-full h-full object-cover rounded-3xl"
            />
          </div>

          <FadeInSection>
            <div id="sobre-julio" className="w-full mt-20 flex flex-col md:flex-row items-center justify-center">
              <div className="w-full">
                <p className="text-[18px] md:text-[22px] text-white roboto-flex-font mb-6">
                  Nosso diferencial está na experiência de nosso time, liderado por Júlio César Müller, CEO premiado e reconhecido internacionalmente por sua contribuição ao setor de energia renovável. Sob sua liderança, desenvolvemos projetos de alto impacto, promovendo eficiência energética e sustentabilidade.
                </p>
              </div>
              <div className="w-full flex justify-center">
                <img src={julio_cesar} alt="Júlio César Müller" className="w-full mt-20 max-w-md rounded-lg shadow-lg z-50" />
              </div>
            </div>
          </FadeInSection>

          <div className="w-[90%] mx-auto h-[1px] bg-[#90ff3c] mt-[20vh] rounded-3xl my-32"></div>

          <FadeInSection>
            <div className="w-full flex flex-col md:flex-row items-center justify-center mb-20">
              <div className="w-full flex justify-center">
                <img src={clean} alt="Imagem Clean" className="w-full max-w-md rounded-lg shadow-lg z-50" />
              </div>
              <div className="w-full">
                <p className="text-[18px] mt-20 md:mt-4 sm:mt-4 md:text-[22px] justify-center items-center text-white roboto-flex-font mb-6">
                  Com uma abordagem personalizada e foco em excelência, a VoltMAIS está comprometida em oferecer soluções completas, desde o planejamento estratégico até a execução dos projetos, sempre priorizando qualidade, segurança e retorno sobre o investimento.
                </p>
              </div>
            </div>
          </FadeInSection>

          <div className="h-[150px] w-[1px] bg-[#90ff3c] my-10 rounded-3xl"></div>

          <h2 className="w-full md:w-[70%] text-3xl md:text-4xl xl:text-5xl font-normal text-center text-white mt-6 AcuminPro-font leading-tight my-20">
            Estamos prontos para impulsionar o futuro energético com tecnologias que fazem a diferença
          </h2>

          <Link to="/solutions" state={{ scrollToTop: true }}>
            <div className='flex justify-center items-center'>
              <button className="relative px-8 py-2 rounded-full bg-white isolation-auto z-10 border-2 border-[#1060fe] text-[#1060fe]
                before:absolute before:w-full before:transition-all
                before:duration-700 before:hover:w-full before:-right-full
                before:hover:right-0 before:rounded-full  before:bg-[#54ff00] before:-z-10
                before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-700 robot-flex-font">Nossas soluções</button>
            </div>
          </Link>

          <img
            src={nuvem}
            alt="Nuvem 1"
            className="absolute w-[500px] md:w-[800px] h-auto z-0"
            loading="lazy"
            style={{ top: '-5vh', left: '-8%' }}
          />
          <img
            src={nuvem}
            alt="Nuvem 2"
            className="absolute w-[500px] md:w-[800px] h-auto z-0"
            loading="lazy"
            style={{ top: '105vh', right: '-30%' }}
          />
          <img
            src={nuvem}
            alt="Nuvem 3"
            className="absolute w-[500px] md:w-[800px] h-auto z-0"
            loading="lazy"
            style={{ top: '265vh', left: '75%' }}
          />
        </div>
      </div>
      <WhatsAppButton />
      <Footer />
    </div>
  );
}

export default AboutUs;
