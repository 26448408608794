import React from 'react'
import '../home.css';

const Investimento = () => {
  return (
    <div className="containerSun">

  </div>
  )
}

export default Investimento
