import React from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // Importação do componente da biblioteca
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';

const QrCode = () => {
  const qrValue = 'http://voltmais.com.br/bio'; // Valor que será convertido em QR code

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <div className="flex-grow flex justify-center items-center">
          <QRCodeCanvas value={qrValue} /> {/* Exibe o QR code */}
        </div>
        <Footer /> {/* Footer fixo no bottom */}
      </div>
    </>
  );
};

export default QrCode;
