import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import { Link } from 'react-router-dom'
import { FaLongArrowAltRight } from 'react-icons/fa'
import Footer from '../../components/footer/Footer'

const LearnMore = () => {
  return (
    <>
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow flex justify-center items-center pt-48">
        <h1>SAIBA MAIS</h1>
        <Link to="/" state={{ scrollToTop: true }}>
          <button
            className="group flex items-center border border-white text-white px-1 py-1 rounded-full transition-all duration-300 hover:bg-white hover:text-[#0055fe]"
            aria-label="Saiba mais sobre nós"
          >
            <span className="text-[16px] font-light mx-3">INICIO</span>
            <span className="w-8 h-8 bg-[#4ed44f] flex items-center justify-center rounded-full group-hover:bg-[#0055fe]">
              <FaLongArrowAltRight className="text-[#0055fe] group-hover:text-white transition-colors duration-300" />
            </span>
          </button>
          </Link>
      </div>
      <Footer/> {/* Footer fixo no bottom */}
    </div>
  </>
  )
}

export default LearnMore
