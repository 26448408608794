import React from 'react';
import whatsappIcon from '../../assets/whatsapp.webp';
import './WhatsAppButton.css';

const WhatsAppButton = () => {
  const phoneNumber = "5551998356981";
  const message = "Olá, preciso de ajuda na área de Elétrica Sustentável. Poderia me ajudar?";
  const encodedMessage = encodeURIComponent(message);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <a
      href={whatsappLink}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={whatsappIcon}
        loading="eager"
        decoding="async"
        alt="WhatsApp icone"
      />
    </a>
  );
}

export default WhatsAppButton;
