// src/services/api.ts
export const enviarEmail = async (formData: any) => {
  try {
    const response = await fetch('https://voltmais.squareweb.app/enviar-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      return { success: true };
    } else {
      return { success: false, message: 'Erro ao enviar a mensagem.' };
    }
  } catch (error) {
    console.error('Erro:', error);
    return { success: false, message: 'Erro ao enviar a mensagem.' };
  }
};
