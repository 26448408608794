import React from 'react';
import { CiMapPin } from "react-icons/ci";
import { MdEmail } from "react-icons/md";
import { FaPhoneFlip } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { FaInfoCircle } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { BsCashCoin } from "react-icons/bs";
import { MdContactSupport } from "react-icons/md";

const Footer = () => {
  const anoAtual = new Date().getFullYear();

  return (
    <div className="relative bg-white w-full flex flex-col items-center py-10 z-40">
      <div className="flex flex-col md:flex-row items-start justify-center w-full gap-5 px-5">
        <h2 className="w-full md:w-[40%] text-[30px] md:text-[40px] text-[#1060fe] font-bold whitespace-nowrap nirmala-font text-center md:text-left">
          <span className="text-[#90ff3c]">Soluções</span> <br />
          elétricas <br />
          sustentáveis
        </h2>
        <div className="hidden md:block h-[175px] w-[1px] bg-[#a6b3d1]"></div>
        <div className="w-full md:w-[25%] flex flex-col items-start justify-start gap-2">
          <h4 className="text-[18px] text-[#a6b3d1] roboto-flex-font">Menu</h4>
          <div className="flex flex-col items-start gap-2">
            <div className="flex items-center gap-2 group">
              <Link to="/aboutUs" state={{ scrollToTop: true }} className="flex items-center gap-2">
                <FaInfoCircle className="text-[#1060fe] group-hover:text-[#90ff3c] transition-colors" />
                <h4 className="text-[16px] md:text-[18px] text-[#1060fe] group-hover:text-[#90ff3c] transition-colors roboto-flex-font">Sobre nós</h4>
              </Link>
            </div>
            <div className="flex items-center gap-2 group">
              <Link to="/solutions" state={{ scrollToTop: true }} className="flex items-center gap-2">
                <IoMdSettings className="text-[#1060fe] group-hover:text-[#90ff3c] transition-colors" />
                <h4 className="text-[16px] md:text-[18px] text-[#1060fe] group-hover:text-[#90ff3c] transition-colors roboto-flex-font">Soluções</h4>
              </Link>
            </div>
            <div className="flex items-center gap-2 group">
              <Link to="/industry" state={{ scrollToTop: true }} className="flex items-center gap-2">
                <BsCashCoin className="text-[#1060fe] group-hover:text-[#90ff3c] transition-colors" />
                <h4 className="text-[16px] md:text-[18px] text-[#1060fe] group-hover:text-[#90ff3c] transition-colors roboto-flex-font">Áreas de Investimento</h4>
              </Link>
            </div>
            <div className="flex items-center gap-2 group">
              <Link to="/contact" state={{ scrollToTop: true }} className="flex items-center gap-2">
                <MdContactSupport className="text-[#1060fe] group-hover:text-[#90ff3c] transition-colors" />
                <h4 className="text-[16px] md:text-[18px] text-[#1060fe] group-hover:text-[#90ff3c] transition-colors roboto-flex-font">Contate-nos</h4>
              </Link>
            </div>
          </div>
        </div>

        <div className="hidden md:block h-[175px] w-[1px] bg-[#a6b3d1]"></div>
        <div className="w-full md:w-[25%] flex flex-col items-start justify-start gap-2 group">
          <h4 className="text-[18px] text-[#a6b3d1] roboto-flex-font">Contato</h4>
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex items-center gap-2 group">
              <a
                href='https://maps.app.goo.gl/2w1p9vSLX5NS6oLB9'
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 group hover:text-[#90ff3c] transition-colors"
              >
                <CiMapPin className="text-[#1060fe] text-xl" />
                <h4 className="text-[16px] md:text-[18px] text-[#1060fe] hover:text-[#90ff3c] roboto-flex-font">
                  BR 116, nº 30 - Rincão dos Ilhéus<br />Estância Velha/RS, 93608-350
                </h4>
              </a>
            </div>
            <div className="flex items-center gap-2 group">
              <a href="tel:+5551998451418" className="flex items-center gap-2 group hover:text-[#90ff3c] transition-colors">
                <FaPhoneFlip className="text-[#1060fe] text-xl" />
                <h4 className="text-[16px] md:text-[18px] text-[#1060fe] hover:text-[#90ff3c] roboto-flex-font">+55-51 99835-6981</h4>
              </a>
            </div>
            <div className="flex items-center gap-2 group">
              <a href="mailto:comercial@voltmais.com.br" className="flex items-center gap-2 group hover:text-[#90ff3c] transition-colors">
                <MdEmail className="text-[#1060fe] text-xl" />
                <h4 className="text-[16px] md:text-[18px] text-[#1060fe] hover:text-[#90ff3c] roboto-flex-font">comercial@voltmais.com.br</h4>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-start justify-between w-[96%] mt-10 md:mt-60">
        <div className="flex flex-col gap-2 w-full md:w-96">
          <a href="https://www.linkedin.com/company/voltmaisenergia/posts/?feedView=all" target="_blank" rel="noopener noreferrer" className="text-[30px] md:text-[35px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors">
            Linkedin
          </a>
          <a href="https://www.instagram.com/voltmais/" target="_blank" rel="noopener noreferrer" className="text-[30px] md:text-[35px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors">
            Instagram
          </a>
          <a href="https://www.facebook.com/VoltMAIS" target="_blank" rel="noopener noreferrer" className="text-[30px] md:text-[35px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors">
            Facebook
          </a>
        </div>

        <div className="w-full mt-auto pt-10">
          <div className="flex justify-end items-center px-4">
            <Link to="/privacyPolicy" state={{ scrollToTop: true }}>
              <h4 className="text-[10px] md:text-[18px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors px-2">Política de Privacidade</h4>
            </Link>
            <a className="text-[10px] md:text-[18px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors" href='https://prospectusnbn.com.br/bio' target='_blank' rel='noopener noreferrer'>
              Desenvolvido por PROSPECTUS NBN©️
            </a>&nbsp;<span className='text-[#1060fe] text-[10px] md:text-[18px]'>{anoAtual}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
