import React from "react";
import { FaPhoneFlip } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import Logo from "../../../src/assets/voltmais_completo.webp";

const NavbarSales: React.FC = () => {
  return (
    <div id='navbarsales' className="fixed w-full flex flex-col md:flex-row justify-between items-center px-3 md:px-5 py-3 md:py-5 z-50 bg-[#0055fe] text-white overflow-hidden">
      <div className="flex justify-center md:justify-start w-full md:w-auto">
        <img
          className="w-[120px] md:w-[150px] mx-auto"
          src={Logo}
          alt="Logo Voltmais"
        />
      </div>

      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 text-center md:text-right w-full md:w-auto mt-3 md:mt-0">
        <a
          href="tel:+5551998451418"
          className="flex items-center justify-center gap-2 group hover:text-[#90ff3c] transition-colors"
        >
          <FaPhoneFlip className="text-[#fff] text-lg md:text-xl" />
          <h4 className="text-[14px] md:text-[16px] lg:text-[18px] text-[#fff] hover:text-[#90ff3c] nirmala-font">
            +55-51 99835-6981
          </h4>
        </a>
        <a
          href="mailto:comercial@voltmais.com.br"
          className="flex items-center justify-center gap-2 group hover:text-[#90ff3c] transition-colors"
        >
          <MdEmail className="text-[#fff] text-lg md:text-xl" />
          <h4 className="text-[14px] md:text-[16px] lg:text-[18px] text-[#fff] hover:text-[#90ff3c] nirmala-font">
            comercial@voltmais.com.br
          </h4>
        </a>
      </div>
    </div>
  );
};

export default NavbarSales;
