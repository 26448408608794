import React, { useState, useEffect, useMemo } from 'react';
import BannerHome from '../../../src/assets/banner_home.webp';
import usina_de_energia_solar from '../../../src/assets/usina_de_energia_solar.webp';
import nuvem from '../../../src/assets/nuvem.webp';
import v_voltmais from '../../../src/assets/v_voltmais.webp';
import sol_voltmais from '../../../src/assets/+sol_voltmais.webp';
import { FaLongArrowAltRight } from "react-icons/fa";
import YouTube from 'react-youtube';
import emailjs from 'emailjs-com';
import './sales.css';
import WhatsAppButton from '../../components/WhatsAppButton/WhatsAppButton';
import NavbarSales from './NavbarSales';

const Sales: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(3600);
  const [formData, setFormData] = useState({
    nome: '',
    telefone: '',
    email: '',
    mensagem: '',
    consumo: '',
  });
  const [status, setStatus] = useState<string | null>(null);

  useEffect(() => {
    if (timeLeft <= 0) return;
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime <= 1 ? 0 : prevTime - 1));
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = useMemo(() => {
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }, [timeLeft]);

  const isMobile = useState(false);

  const videoOpts = useMemo(() => ({
    width: '100%',
    height: isMobile ? '700px' : '400px',
    playerVars: {
      autoplay: 0,
      controls: 0,
      modestbranding: 0,
      rel: 0,
      showinfo: 0,
      loop: 0,
      playsinline: 1,
      mute: 0,
    },
  }), [isMobile]);

  const scrollToSection = (id: string, yOffset: number = -100) => {
    const section = document.getElementById(id);
    if (!section) {
      console.warn(`Elemento com id "${id}" não encontrado.`);
      return;
    }

    const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const handleWhatsAppMessage = (solutionText: string) => {
    const phoneNumber = '5551998356981';
    const message = `Olá! ${solutionText}`;
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-16765930456/M3TFCPiT0uYZENiXz7o-',
        value: 1.0,
        currency: 'BRL',
      });
    }
    window.open(whatsappLink, '_blank');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Ativa o estado de carregamento
    setStatus('Enviando...');

    emailjs
      .send(
        'service_9d1le0e_Voltmais',
        'template_6wo39co',
        {
          nome: formData.nome,
          telefone: formData.telefone,
          email: formData.email,
          mensagem: formData.mensagem,
          consumo: formData.consumo,
        },
        'kJd01MWr4-OWO4ja1'
      )
      .then(
        () => {
          setStatus('E-mail enviado com sucesso!');
          setLoading(false);
          setFormData({ nome: '', telefone: '', email: '', mensagem: '', consumo: '' });
          if (typeof window.gtag === 'function') {
            window.gtag('event', 'conversion', {
              send_to: 'AW-16765930456/M3TFCPiT0uYZENiXz7o-',
              value: 1.0,
              currency: 'BRL',
            });
          }
        },
        (error) => {
          console.error('Erro ao enviar o e-mail:', error);
          setStatus('Erro ao enviar o e-mail.');
          setLoading(false);
        }
      );
  };

  return (
    <div id='sales' className="bg-[#0055fe] w-full text-white overflow-hidden font-[Nirmala]">
      <NavbarSales />
      <div
        className="relative min-h-[100vh] md:min-h-[95vh] sm:min-h-[90vh] bg-cover bg-center flex flex-col justify-center sm:justify-end items-center text-white px-6 sm:px-10 lg:px-20"
        style={{
          backgroundImage: `linear-gradient(to top, rgba(0, 85, 254, 1), rgba(0, 85, 254, 0)), url(${BannerHome})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="relative w-full flex flex-col sm:flex-row justify-between items-center sm:items-end mb-8 sm:mb-16">
          <div className="w-full sm:w-[60%] flex items-center sm:items-end justify-center sm:justify-start mb-2 sm:mb-0">
            <h1 className="text-[10vw] sm:text-[8vw] max-w-3xl leading-[0.9] text-center sm:text-left">
              <span className="text-[#4ed44f] nirmala-font">Soluções</span> <br />
              <span className="text-white nirmala-font">Elétricas</span> <br />
              <span className="text-white nirmala-font">Sustentáveis</span>
            </h1>
          </div>

          <div className="w-full sm:w-[40%] flex flex-col items-center sm:items-start justify-center mt-2 sm:mt-0 sm:ml-12 lg:ml-36">
            <p className="text-[4vw] sm:text-[1.5vw] leading-tight text-white text-center sm:text-left mb-6 sm:mb-16 nirmala-font max-w-[300px] sm:max-w-xl">
              Especialistas em desenvolvimento <br />
              de projetos de energia fotovoltaica, <br />
              elétricos industriais, comerciais e <br />
              residenciais
            </p>
            <div className="w-full sm:w-auto flex justify-center sm:justify-start">
              <button
                onClick={() => handleWhatsAppMessage('Olá. Tenho interesse em economizar com as soluções elétricas sustentáveis da Voltmais!')}
                className="group flex items-center justify-center border border-white text-white px-1 py-1 rounded-full transition-all duration-300 hover:bg-white hover:text-[#0055fe] transform hover:scale-110"
                aria-label="Garanta seu desconto e comece a economizar agora!"
              >
                <span className="font-medium text-md sm:text-xl mx-4 nirmala-font">Comece a economizar agora!</span>
                <span className="w-8 h-8 sm:w-12 sm:h-12 bg-[#4ed44f] flex items-center justify-center rounded-full group-hover:bg-[#0055fe] group-hover:translate-x-1 transition-all duration-300">
                  <FaLongArrowAltRight className="text-[#0055fe] group-hover:text-white transition-colors duration-300" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-px bg-[#90ff3c] mt-8 sm:mb-16 absolute bottom-16 sm:static"></div>

      <img
        src={nuvem}
        alt="Nuvem 1"
        className="absolute w-[800px] top-20 left-0 h-auto z-10 opacity-30"
        loading='lazy'
      />
      <div
  className="relative min-h-screen bg-cover bg-center flex flex-col justify-center items-center text-white px-6 sm:px-10 lg:px-20 space-y-16 z-40 overflow-hidden sm:overflow-x-hidden"
  style={{
    backgroundImage: `linear-gradient(to top, rgba(0, 85, 254, 1), rgba(0, 85, 254, 0)), url(${BannerHome})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}
>
  <h1 className="text-4xl sm:text-6xl text-center font-bold nirmala-font pt-20 sm:pt-10">
    Reduza em até <span className="text-[#4ed44f] italic nirmala-font">95%</span> a sua fatura de energia elétrica e contribua para um{' '}
    <span className="text-[#4ed44f] italic nirmala-font">mundo melhor</span> para as gerações futuras!
  </h1>

  <h2 className="text-xl sm:text-2xl text-center nirmala-font">
    Junte-se à mudança global para um futuro mais sustentável com <span className="text-[#4ed44f] italic nirmala-font">energia solar</span> e{' '}
    <span className="text-[#4ed44f] italic nirmala-font">soluções elétricas</span> que fazem a diferença.
  </h2>

  <p className="text-lg sm:text-xl leading-tight text-white nirmala-font text-center mx-4 sm:mx-24">
    Temos a solução completa para reduzir custos, valorizar seu imóvel e garantir um futuro sustentável para as próximas gerações. Com sistemas de energia solar e soluções elétricas personalizadas, você economiza hoje e contribui para um planeta melhor no futuro.
  </p>

  <div className='flex flex-col w-full'>
  <div className="w-full" style={{ aspectRatio: '16/9' }}>
    <YouTube videoId="kvL-BiA4QOc" opts={videoOpts} className="w-full" />
  </div>

  <div className="mt-8 w-full sm:w-auto flex justify-center">
    <button
      onClick={() => scrollToSection('next')}
      className="group flex items-center justify-center border border-white text-white px-1 py-1 rounded-full transition-all duration-300 hover:bg-white hover:text-[#0055fe] transform hover:scale-110"
      aria-label="Saiba mais sobre nós"
    >
      <span className="font-medium text-md sm:text-xl mx-4 nirmala-font">Saiba como você pode economizar agora!</span>
      <span className="w-8 h-8 sm:w-12 sm:h-12 bg-[#4ed44f] flex items-center justify-center rounded-full group-hover:bg-[#0055fe] group-hover:translate-x-1 transition-all duration-300">
        <FaLongArrowAltRight className="text-[#0055fe] group-hover:text-white transition-colors duration-300" />
      </span>
    </button>
  </div>
  </div>
</div>

      <div id='next' className="flex flex-col justify-center items-center min-h-[65vh] mx-2 sm:mx-[2.5%] mt-10 bg-[#1060fe] text-white rounded-t-3xl p-6 sm:p-8 lg:p-12 space-y-4 sm:space-y-5">
        <h1 className="text-2xl sm:text-3xl text-center font-semibold nirmala-font pt-20 sm:pt-0">Economize com a <span className="text-[#4ed44f] italic nirmala-font">VoltMAIS </span>usando nossas Soluções Personalizadas!</h1>
        <h2 className="text-lg sm:text-2xl text-center nirmala-font">Na VoltMAIS, oferecemos soluções <span className="text-[#4ed44f] italic nirmala-font">elétricas </span> e de <span className="text-[#4ed44f] italic nirmala-font">energia solar </span> 100% personalizadas para atender às necessidades específicas de cada cliente. Isso significa que, além de economizar na conta de energia, você investe em um projeto planejado para maximizar seu retorno e garantir eficiência e segurança.</h2>
        <div className="mt-10 py-14 w-full sm:w-auto flex justify-center sm:justify-start">
          <button
            onClick={() => handleWhatsAppMessage('Quero agendar uma conversa com um especialista sobre soluções elétricas sustentáveis.')}
            className="group flex items-center justify-center border border-white text-white px-1 py-1 rounded-full transition-all duration-300 hover:bg-white hover:text-[#0055fe] transform hover:scale-110"
            aria-label="Agendar consulta com um especialista"
          >
            <span className="font-medium text-sm sm:text-xl mx-2 nirmala-font">Agendar consulta com um especialista</span>
            <span className="w-8 h-8 sm:w-12 sm:h-12 bg-[#4ed44f] flex items-center justify-center rounded-full group-hover:bg-[#0055fe] group-hover:translate-x-1 transition-all duration-300">
              <FaLongArrowAltRight className="text-[#0055fe] group-hover:text-white transition-colors duration-300" />
            </span>
          </button>
        </div>
      </div>

      <div className="relative flex flex-col mt-12 sm:mt-[50px] mx-2 sm:mx-[2.5%] bg-[#004bb5] text-white p-6 sm:p-8 rounded-b-3xl min-h-[85vh] justify-center items-center z-40">
        <div className="absolute top-0 left-0 p-4 text-4xl text-[#4ed44f]">
          <span role="img" aria-label="Alerta">⚠️</span>
        </div>

        <h1 className="text-2xl sm:text-[35px] md:text-[55px] font-semibold m-4 sm:m-6 leading-snug text-center AcuminPro-font uppercase">
          O CUSTO DA ENERGIA ESTÁ SUBINDO CONSTANTEMENTE, DEIXANDO FAMÍLIAS E EMPRESAS VULNERÁVEIS E DEPENDENTES DE UMA REDE ELÉTRICA CADA VEZ MAIS CARA E INSTÁVEL. <br /><span className="text-[#4ed44f]">É HORA DE AGIR IMEDIATAMENTE!</span>
        </h1>

        <h1 className="text-2xl sm:text-[35px] md:text-[55px] font-semibold m-4 sm:m-6 leading-snug text-center AcuminPro-font uppercase">
          A FALTA DE EFICIÊNCIA ENERGÉTICA É UMA PREOCUPAÇÃO REAL: ALÉM DO IMPACTO NO BOLSO, HÁ O RISCO DE SOBRECARGA E INSEGURANÇA NA REDE ELÉTRICA. <br /><span className="text-[#4ed44f]">NÃO ESPERE! RESOLVA AGORA!</span>
        </h1>

        <div className="mt-10 py-14 w-full sm:w-auto flex justify-center sm:justify-start">
          <button
            onClick={() => scrollToSection('benefits')}
            className="group flex items-center justify-center border border-white text-white px-1 py-1 rounded-full transition-all duration-300 hover:bg-white hover:text-[#0055fe] transform hover:scale-110"
            aria-label="Tomar ação agora"
          >
            <span className="font-medium text-md sm:text-xl mx-4 nirmala-font">TOMAR AÇÃO AGORA</span>
            <span className="w-8 h-8 sm:w-12 sm:h-12 bg-[#4ed44f] flex items-center justify-center rounded-full group-hover:bg-[#0055fe] group-hover:translate-x-1 transition-all duration-300">
              <FaLongArrowAltRight className="text-[#0055fe] group-hover:text-white transition-colors duration-300" />
            </span>
          </button>
        </div>
      </div>

      <div className="relative">
        <img
          src={v_voltmais}
          alt="Logo Voltmais"
          className="absolute w-[60vw] top-[10px] sm:top-[10px] md:top-[10px] left-80 -translate-x-1/2 h-auto z-0 opacity-20"
          loading='lazy'
        />

        <div className="relative z-10 flex flex-col sm:flex-row justify-center items-center w-100% sm:mx-[2.5%] text-black p-6 sm:p-8 rounded-lg shadow-lg mx-2 my-8 bg-transparent">
          <div className="text-center w-full sm:1/2 sm:text-left mb-4 sm:mb-0 z-10">
            <p className="text-white text-5xl md:text-5xl lg:text-6xl mb-8 text-center AcuminPro-font px-0 sm:mx-4 md:mx-4 lg:mx-8 tracking-tight">
              RECEBA OFERTAS IMPERDÍVEIS!
            </p>
            <p className="text-white text-2xl md:text-3xl lg:text-4xl mb-6 text-center AcuminPro-font px-0 sm:mx-4 md:mx-4 lg:mx-8 leading-tight">
              Cadastre-se e descubra <span className="text-[#4ed44f] bold AcuminPro-font tracking-tighter">como economizar </span> e garantir sua segurança energética com as soluções da <span className="text-[#4ed44f] bold italic"> VoltMAIS</span>.
            </p>
            <p className="text-white text-2xl md:text-3xl lg:text-4xl mb-6 text-center AcuminPro-font px-0 sm:mx-4 md:mx-4 lg:mx-8 leading-tight">
              Não perca a chance de transformar sua energia em economia!
            </p>
          </div>

          <div className="w-full max-w-md mx-auto relative overflow-hidden z-20 bg-white p-6 md:p-8 rounded-lg shadow-md">
            <h2 className="text-xl md:text-3xl text-[#0055fe] font-bold mb-6 text-center lg:text-left">
              Entre em Contato
            </h2>

            <form onSubmit={handleSubmit} className="space-y-4 z-50">
              <div>
                <label className="block text-md font-medium text-[#0055fe]" htmlFor="nome">
                  Nome Completo
                </label>
                <input
                  className="mt-1 p-2 w-full border rounded-md text-base md:text-md"
                  type="text"
                  id="nome"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label className="block text-md font-medium text-[#0055fe]" htmlFor="telefone">
                  Telefone
                </label>
                <input
                  className="mt-1 p-2 w-full border rounded-md text-base md:text-md"
                  type="tel"
                  id="telefone"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleChange}
                  pattern="\(?\d{2}\)?[\s-]?\d{5}-?\d{4}"
                  title="O número de telefone deve estar no formato (11) 98765-4321 ou 11987654321"
                  required
                />
              </div>

              <div>
                <label className="block text-md font-medium text-[#0055fe]" htmlFor="email">
                  E-mail
                </label>
                <input
                  className="mt-1 p-2 w-full border rounded-md text-base md:text-md"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  title="Por favor, insira um e-mail válido, como exemplo@dominio.com"
                  required
                />
              </div>

              <div>
                <label className="block text-md font-medium text-[#0055fe] appearance-none" htmlFor="consumo">
                  Valor Médio da Fatura de Energia (R$)
                </label>
                <input
                  className="mt-1 p-2 w-full border rounded-md text-base md:text-md"
                  type="number"
                  id="consumo"
                  name="consumo"
                  value={formData.consumo}
                  onChange={handleChange}
                  placeholder="Ex: 250,00"
                  min="0"
                  step="0.01"
                  required
                />
              </div>

              <div>
                <label className="block text-md font-medium text-[#0055fe]" htmlFor="mensagem">
                  Mensagem
                </label>
                <textarea
                  className="mt-1 p-2 w-full border rounded-md text-base md:text-md"
                  rows={4}
                  id="mensagem"
                  name="mensagem"
                  value={formData.mensagem}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <div className="flex justify-center lg:justify-end">
                <button
                  className="w-full md:w-auto bg-gradient-to-r from-[#0055fe] via-[#2b72ff] to-[#0055fe] text-white px-6 py-3 font-bold rounded-md hover:opacity-80 text-base md:text-md"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Enviando...' : 'Enviar Mensagem'}
                </button>
              </div>

              {loading && (
                <div className="flex-col gap-4 w-full flex items-center justify-center mt-4">
                  <div
                    className="w-20 h-20 border-4 border-transparent text-[#0055fe] text-4xl animate-spin flex items-center justify-center border-t-[#0055fe] rounded-full"
                  >
                    <div
                      className="w-16 h-16 border-4 border-transparent text-[#4ed44f] text-2xl animate-spin flex items-center justify-center border-t-[#4ed44f] rounded-full"
                    ></div>
                  </div>
                </div>
              )}

              {status && (
                <p
                  className={`mt-4 p-4 rounded-md text-center font-semibold transition-all duration-300 ease-in-out ${status.includes('sucesso') ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-500'}`}
                  style={{ opacity: status ? 1 : 0 }}
                >
                  <span className={`mr-2 ${status.includes('sucesso') ? 'text-green-700' : 'text-red-700'}`}>
                    {status.includes('sucesso') ? '✅' : ''}
                  </span>
                  {status}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col md:flex-col px-5 sm:px-20 items-center justify-center w-full rounded-b-3xl z-40 mt-12 sm:-mt-10 min-h-[100vh] bg-cover bg-bottom overflow-hidden" style={{ backgroundImage: `linear-gradient(to top, rgba(0, 85, 254, 1), rgba(0, 85, 254, 0)), url(${usina_de_energia_solar})` }}>
        <p className="text-3xl text-center sm:text-4xl leading-tight text-white AcuminPro-font px-4 sm:px-0 py-16 z-50">
          IMAGINE PODER CONTAR COM UMA SOLUÇÃO DE ENERGIA QUE <b className='text-[#4ed44f]'>REDUZ CUSTOS</b>, AUMENTA A SEGURANÇA E AINDA VALORIZA SEU IMÓVEL. <br />
          COM NOSSOS SISTEMAS DE ENERGIA SOLAR E SERVIÇOS ELÉTRICOS COMPLETOS, <b className='text-[#4ed44f]'>VOCÊ</b> CONQUISTA EFICIÊNCIA, TRANQUILIDADE E SUSTENTABILIDADE.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 sm:gap-8 pb-10 sm:pb-0 z-50">
          {['Econômia', 'Segurança', 'Sustentabilidade', 'Versatilidade'].map((item, index) => (
            <div key={index} className="bg-[#f2f6ff] p-6 sm:p-10 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-3xl sm:text-5xl text-[#1060fe] font-semibold mb-4 sm:mb-6 AcuminPro-font">{item}</h3>
              <p className="text-base sm:text-xl nirmala-font text-[#1060fe] leading-relaxed">
                {index === 0 ? 'Economia no Presente: redução de até 95% na sua conta de energia.' :
                  index === 1 ? 'Segurança para sua Família e Empresa: fornecimento confiável e sem oscilações.' :
                    index === 2 ? 'Sustentabilidade para o Futuro: energia limpa que reduz a pegada de carbono e ajuda a construir um planeta melhor para as próximas gerações.' :
                      'Versatilidade: soluções adaptáveis às suas necessidades específicas.'}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col justify-center mt-12 sm:justify-center items-center text-center min-h-[65vh] mx-4 sm:mx-[2.5%] bg-gradient-to-br from-[#004bb5] to-[#1060fe] text-white rounded-t-3xl p-8 lg:p-12 space-y-10 shadow-lg">
        <p className="text-[4vw] sm:text-[2.5vw] lg:text-[2.5vw] font-bold leading-tight text-white AcuminPro-font mx-4 sm:mx-24">
          CLIENTES COMO VOCÊ JÁ ESTÃO DESFRUTANDO DE UM SISTEMA ESTÁVEL, <b className='text-[#4ed44f]'>SEGURO</b> E ECONÔMICO.
          <br />
          VEJA O QUE ELES TÊM A DIZER:
        </p>
        <div className="flex flex-col sm:flex-row items-center gap-6 sm:gap-8 mx-4 sm:mx-24">
          <blockquote className="bg-white bg-opacity-10 border border-opacity-20 border-white rounded-2xl shadow-md text-[4vw] sm:text-[1.4vw] lg:text-[1.2vw] leading-tight text-white nirmala-font p-6 lg:p-8 flex-1 relative">
            <p>“Serviço de avaliação/analise e execução de excelência! <b className='text-[#4ed44f] italic'>Empresa transparente</b> e de fato preocupada em entregar serviço de qualidade e acompanhamento. Super recomendo!”</p>
            <footer className="mt-4 text-[#4ed44f] italic font-semibold">— João M., Empresário</footer>
          </blockquote>

          <blockquote className="bg-white bg-opacity-10 border border-opacity-20 border-white rounded-2xl shadow-md text-[4vw] sm:text-[1.4vw] lg:text-[1.2vw] leading-tight text-white nirmala-font p-6 lg:p-8 flex-1 relative">
            <p>“Nosso condomínio inteiro está economizando! E, além disso, a segurança no fornecimento melhorou muito, garantindo um <b className='text-[#4ed44f] italic'>ambiente mais estável para todos.</b>”</p>
            <footer className="mt-4 text-[#4ed44f] italic font-semibold">— Laura S., Síndica</footer>
          </blockquote>
        </div>
        <div className="w-full flex justify-center my-6">
          <div className="h-[4px] w-[60px] bg-[#4ed44f] animate-pulse rounded-full"></div>
        </div>
        <div className="text-center mt-16">
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white AcuminPro-font uppercase">
            Nossos Serviços
          </h2>
          <p className="text-lg sm:text-xl text-white nirmala-font mt-2">
            Conheça as soluções que oferecemos para atender suas necessidades em energia e instalações elétricas.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-stretch gap-6 sm:gap-8 mx-4 sm:mx-24 mt-10">
          <div className="bg-[#004bb5] bg-opacity-80 p-6 rounded-xl shadow-lg text-white flex-1 hover:bg-opacity-100 transition duration-300">
            <h3 className="text-xl sm:text-2xl lg:text-3xl font-semibold leading-tight nirmala-font">
              Energia Solar
            </h3>
            <p className="text-[4vw] sm:text-[1.4vw] lg:text-[1.2vw] leading-tight nirmala-font mt-4">
              Instalação rápida e eficiente para aproveitar o melhor da energia limpa em qualquer imóvel, com garantia de suporte completo.
            </p>
          </div>

          <div className="bg-[#004bb5] bg-opacity-80 p-6 rounded-xl shadow-lg text-white flex-1 hover:bg-opacity-100 transition duration-300">
            <h3 className="text-xl sm:text-2xl lg:text-3xl font-semibold leading-tight nirmala-font">
              Soluções Elétricas Industriais e Comerciais
            </h3>
            <p className="text-[4vw] sm:text-[1.4vw] lg:text-[1.2vw] leading-tight nirmala-font mt-4">
              Realizamos toda a instalação e manutenção elétrica, assegurando um sistema estável e seguro. Aumente a produtividade com nossa infraestrutura de alta performance.
            </p>
          </div>

          <div className="bg-[#004bb5] bg-opacity-80 p-6 rounded-xl shadow-lg text-white flex-1 hover:bg-opacity-100 transition duration-300">
            <h3 className="text-xl sm:text-2xl lg:text-3xl font-semibold leading-tight nirmala-font">
              Serviços Residenciais e Prediais
            </h3>
            <p className="text-[4vw] sm:text-[1.4vw] lg:text-[1.2vw] leading-tight nirmala-font mt-4">
              Segurança e eficiência para sua residência e edifícios residenciais. Desde a instalação até a manutenção, você conta com um sistema que reduz custos e melhora a segurança.
            </p>
          </div>
        </div>
      </div>

      <img
        src={nuvem}
        alt="Nuvem 3"
        className="absolute w-[500px] top-[3500px] right-0 h-auto z-0 opacity-50"
        loading='lazy'
      />

      <img
        src={sol_voltmais}
        alt="sol voltmais"
        className="absolute top-[5950px] left-12 w-[10vw] h-auto z-0 opacity-20"
        loading="lazy"
      />

      <div id='benefits' className="relative flex flex-col mt-12 mx-4 sm:mx-[2.5%] bg-gradient-to-br from-[#034cdf81] to-transparent text-white text-center p-10 sm:p-12 rounded-b-3xl min-h-[65vh] justify-center items-center space-y-8 z-50 shadow-lg">
        <p className="text-[4vw] sm:text-[1.4vw] leading-tight text-white nirmala-font mx-4 sm:mx-24">
          Confiamos tanto nas nossas soluções que oferecemos uma <b className="text-[#4ed44f]">garantia estendida</b> e <b className="text-[#4ed44f]">manutenção inclusa por 12 meses</b>. Com nosso suporte, você sempre estará protegido e seguro.
        </p>
        <p className="text-[4vw] sm:text-[1.4vw] leading-tight text-white nirmala-font mx-4 sm:mx-24">
          Nossos sistemas de pagamento são <b className="text-[#4ed44f]">100% seguros</b>, protegendo seus dados e oferecendo máxima tranquilidade.
        </p>
        <div className="mt-10 py-14 w-full sm:w-auto flex justify-center sm:justify-start">
          <button
            onClick={() => handleWhatsAppMessage('Quero descobrir quanto posso economizar com as soluções da Voltmais.')}
            className="group flex items-center justify-center border border-white text-white px-1 py-1 rounded-full transition-all duration-300 hover:bg-white hover:text-[#0055fe] transform hover:scale-110"
            aria-label="Clique aqui e descubra quanto você pode economizar!"
          >
            <span className="font-medium text-sm sm:text-xl mx-2 nirmala-font">Clique aqui e descubra quanto você pode economizar!</span>
            <span className="w-8 h-8 sm:w-12 sm:h-12 bg-[#4ed44f] flex items-center justify-center rounded-full group-hover:bg-[#0055fe] group-hover:translate-x-1 transition-all duration-300">
              <FaLongArrowAltRight className="text-[#0055fe] group-hover:text-white transition-colors duration-300" />
            </span>
          </button>
        </div>
      </div>

      <img
        src={nuvem}
        alt="Nuvem 3"
        className="absolute w-[500px] top-[4500px] left-0 h-auto z-20 opacity-50"
        loading='lazy'
      />

      <div className="flex flex-col items-center justify-center min-h-[60vh] text-center space-y-16 bg-gradient-to-br from-[#004bb5] to-[#1060fe] p-10 rounded-xl mx-4 sm:mx-[2.5%] shadow-lg">
        <p className="text-[4vw] sm:text-[1.4vw] lg:text-[1.2vw] font-semibold leading-tight text-white nirmala-font mx-2 sm:mx-24">
          <span className="text-xl sm:text-3xl font-bold text-[#4ed44f]">⚡️BLACK NOVEMBER⚡️</span>
          <br />
          <span className="font-semibold text-white">Vantagem Exclusiva por Tempo Limitado!</span>
          <br />
          Instale energia solar agora utilizando o código: <b className="text-[#4ed44f]">#QUERODESCONTO</b>! ⏳ Não perca essa oportunidade única!
        </p>

        <div className="relative">
          <p className="text-[4vw] sm:text-[1.4vw] lg:text-[1.2vw] font-semibold leading-tight text-white nirmala-font mx-4 sm:mx-24">
            <span className="font-semibold text-[#4ed44f]">⚠️ ATENÇÃO:</span> <span className="text-white font-bold">Vagas Limitadas! </span>
            Estamos atendendo apenas um número restrito de instalações por mês para garantir a qualidade do serviço.
          </p>

          <div className="transform justify-center items-center text-center mt-6 z-50">
            <p className="text-[#4ed44f] font-bold text-[16px] sm:text-2xl lg:text-3xl">
              ⏰ <span className='text-[16px] sm:text-2xl lg:text-3xl'>{formatTime}</span> Restante para garantir sua vaga!
            </p>
          </div>
        </div>

        <div className="mt-10 py-14 w-full sm:w-auto flex justify-center sm:justify-start">
          <button
            onClick={() => handleWhatsAppMessage('Quero garantir meu desconto com o código #QUERODESCONTO e começar a economizar agora com a Voltmais.')}
            className="group flex items-center justify-center border border-white text-white px-1 py-1 rounded-full transition-all duration-300 hover:bg-white hover:text-[#0055fe] transform hover:scale-110"
            aria-label="Garanta seu desconto e comece a economizar agora!"
          >
            <span className="font-medium text-sm sm:text-xl mx-2 nirmala-font">Garanta seu desconto e comece a economizar agora!</span>
            <span className="w-8 h-8 sm:w-12 sm:h-12 bg-[#4ed44f] flex items-center justify-center rounded-full group-hover:bg-[#0055fe] group-hover:translate-x-1 transition-all duration-300">
              <FaLongArrowAltRight className="text-[#0055fe] group-hover:text-white transition-colors duration-300" />
            </span>
          </button>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
};

export default Sales;
