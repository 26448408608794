import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import BioLink from './pages/bioLink/BioLink';
import QrCode from './components/qrCode/QrCode';
import AboutUs from './pages/aboutUs/AboutUs';
import LearnMore from './pages/learnMore/LearnMore';
import Home from './pages/Home';
import Disclaimer from './pages/disclaimer/Disclaimer';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import Industry from './pages/industry/Industry';
import Investimento from './pages/investimento/Investimento';
import Solutions from './pages/solutions/Solutions';
import Contact from './pages/contact/Contact';
import Sales from './pages/sales/Sales';

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return null;
};

const App: React.FC = () => {
  useEffect(() => {
    // Inicializar o Google Analytics
    ReactGA.initialize('G-PL6YYE15HB');

    // Inicializar o Google Tag Manager com o react-gtm-module
    TagManager.initialize({ gtmId: 'AW-16765930456' });
  }, []);

  return (
    <Router>
      <Analytics />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bio" element={<BioLink />} />
        <Route path="/qrcode" element={<QrCode />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/learnMore" element={<LearnMore />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/industry" element={<Industry />} />
        <Route path="/investimento" element={<Investimento />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sales" element={<Sales />} />
      </Routes>
    </Router>
  );
}

export default App;
