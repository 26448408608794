import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import volt from '../../../src/assets/voltmais_completo.webp';
import WhatsAppButton from '../../components/WhatsAppButton/WhatsAppButton';
import FadeInSection from '../../components/FadeInSection/FadeInSection';
import { enviarEmail } from '../../services/api';

const Contact: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [formData, setFormData] = useState({
    nome: '',
    telefone: '',
    email: '',
    mensagem: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await enviarEmail(formData);

    if (result.success) {
      alert('Mensagem enviada com sucesso!');
      setFormData({ nome: '', telefone: '', email: '', mensagem: '' });
    } else {
      alert(result.message);
    }
  };

  return (
    <div className="relative flex flex-col bg-[#0055fe]">
      <Navbar />
      <div className="flex flex-col lg:flex-row min-h-screen justify-center items-center p-4 md:p-8 lg:p-12 bg-[#034cdf81] mx-[2.5%] py-20 md:py-0 sm:py-0 rounded-t-3xl">

        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 text-center lg:text-left">
          <h2 className="text-5xl md:text-7xl lg:text-8xl xl:text-9xl font-normal text-[#4ed44f] mt-6 AcuminPro-font leading-tight">
            Junte-se a<br />
            <img
              src={volt}
              alt="VoltMAIS"
              className="h-10 md:h-16 lg:h-18 xl:h-20 mb-10 mx-auto lg:mx-0"
            />
          </h2>
          <p className="text-white text-2xl md:text-3xl lg:text-4xl mb-10 AcuminPro-font px-4 lg:px-0">
            Preencha o formulário para saber mais sobre nossas soluções elétricas sustentáveis.
          </p>
        </div>

        {/* Formulário à direita */}
        <FadeInSection>
        <div className="w-[330px] sm:max-w-full md:w-[450px] max-w-md mx-auto relative overflow-hidden z-10 bg-white p-6 md:p-8 rounded-lg shadow-md before:w-24 before:h-24 before:absolute before:bg-[#0055fe] before:rounded-full before:-z-10 before:blur-2xl after:w-32 after:h-32 after:absolute after:bg-[#90ff3c] after:rounded-full after:-z-10 after:blur-xl after:top-24 after:-right-12">
            <h2 className="text-xl md:text-3xl text-black font-bold mb-6 text-center lg:text-left">
              Entre em Contato
            </h2>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-md font-medium text-black" htmlFor="nome">
                  Nome Completo
                </label>
                <input
                  className="mt-1 p-2 w-full border rounded-md text-base md:text-md"
                  type="text"
                  id="nome"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label className="block text-md font-medium text-black" htmlFor="telefone">
                  Telefone
                </label>
                <input
                  className="mt-1 p-2 w-full border rounded-md text-base md:text-md"
                  type="tel"
                  id="telefone"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleChange}
                  pattern="\(?\d{2}\)?[\s-]?\d{5}-?\d{4}"
                  title="O número de telefone deve estar no formato (11) 98765-4321 ou 11987654321"
                  required
                />
              </div>

              <div>
                <label className="block text-md font-medium text-black" htmlFor="email">
                  E-mail
                </label>
                <input
                  className="mt-1 p-2 w-full border rounded-md text-base md:text-md"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  title="Por favor, insira um e-mail válido, como exemplo@dominio.com"
                  required
                />
              </div>

              <div>
                <label className="block text-md font-medium text-black" htmlFor="mensagem">
                  Mensagem
                </label>
                <textarea
                  className="mt-1 p-2 w-full border rounded-md text-base md:text-md"
                  rows={4}
                  id="mensagem"
                  name="mensagem"
                  value={formData.mensagem}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <div className="flex justify-center lg:justify-end">
                <button
                  className="w-full md:w-auto bg-gradient-to-r from-[#0055fe] via-[#2b72ff] to-[#0055fe] text-white px-6 py-3 font-bold rounded-md hover:opacity-80 text-base md:text-md"
                  type="submit"
                >
                  Enviar Mensagem
                </button>
              </div>
            </form>
          </div>
        </FadeInSection>
      </div>
      <WhatsAppButton />
      <Footer />
    </div>
  );
};

export default Contact;
