import React, { useState, useEffect } from 'react'
import YouTube from 'react-youtube';
import green_bg from '../../assets/green_bg.webp';
import nuvem from '../../../src/assets/nuvem.webp';
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useLocation } from 'react-router-dom'
import FadeInSection from '../../components/FadeInSection/FadeInSection';
import WhatsAppButton from '../../components/WhatsAppButton/WhatsAppButton';

const Industry = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    empresa: '',
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const videoOpts = {
    width: '100%',
    height: isMobile ? '100%' : '100%',
    playerVars: {
      autoplay: 1,
      controls: 0,
      modestbranding: 0,
      rel: 0,
      showinfo: 0,
      loop: 0,
      playsinline: 0,
      mute: 0,
    },
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const phoneNumber = "5551998356981";
    const message = `Olá, meu nome é ${formData.nome}, meu e-mail é ${formData.email}, meu telefone é ${formData.telefone}, e minha empresa é ${formData.empresa}. Gostaria de agendar uma consultoria gratuita.`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.open(whatsappLink, '_blank');
  };

  const handleSubmit2 = (e: any) => {
    e.preventDefault();  // Previne o comportamento padrão do botão

    const phoneNumber = "5551998356981";  // Número do WhatsApp
    const message = `Olá, preciso de ajuda na área de soluções elétricas industriais sustentáveis.`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.open(whatsappLink, '_blank');
  };

  return (
    <div className='overflow-hidden'>
      <Navbar />
      <FadeInSection>
      <div className={`relative ${isMobile ? 'h-[200px]' : 'h-[500px]'} w-full mt-12 overflow-x-hidden`}>
          <YouTube
            videoId="ebssQvq9VX4"
            opts={videoOpts}
            className={`absolute top-0 left-0 w-full h-full overflow-hidden z-40`}
          />
        </div>
      </FadeInSection>
      <div className="relative flex flex-col mx-[2.5%] bg-[#034cdf81] text-white px-8 rounded-b-3xl items-center overflow-hidden z-40">
        <FadeInSection>
          <section className="py-20 text-white">
            <div className="container mx-auto px-4">
              <h2 className="text-7xl font-bold text-center mb-12 AcuminPro-font">NOSSO DIFERENCIAL</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {['Experiência', 'Liderança', 'Inovação'].map((item, index) => (
                  <div key={index} className="bg-[#034cdf8c] p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                    <h3 className="text-5xl font-semibold mb-4 AcuminPro-font">{item}</h3>
                    <p className="text-2xl nirmala-font">{index === 0 ? '+22 anos no mercado de energia elétrica sustentável.' :
                      index === 1 ? 'Júlio César Müller - Reconhecido Internacionalmente por implementar no Brasil soluções elétricas sustentáveis.' :
                        'Tecnologia de ponta em soluções elétricas sustentáveis.'}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <img
        src={nuvem}
        alt="Nuvem 1"
        className="absolute w-[800px] h-auto z-10 opacity-30"
        style={{ top: '20%', left: '-15%' }}
      />
        </FadeInSection>
        <div className="h-[125px] w-[1px] bg-[#4ed44f] mb-10 mx-2"></div>
        <FadeInSection>
          <section className="py-20 text-white">
            <div className="container mx-auto px-4">
              <h2 className="text-7xl font-bold text-center mb-8 AcuminPro-font">ENFRENTE OS DESAFÍOS CRÍTICOS DO MERCADO</h2>
              <ul className="list-disc list-inside text-2xl mb-8 max-w-2xl mx-auto nirmala-font">
                <li>Pressão para redução de custos</li>
                <li>Necessidade de eficiência energética</li>
                <li>Exigências de sustentabilidade e ESG</li>
              </ul>
              <div className="text-center">
                <button
                  onClick={handleSubmit2}
                  className="group flex items-center border border-white text-[#fff] px-1 py-1 rounded-full transition-all duration-300 hover:bg-white hover:text-[#0055fe]"
                  aria-label="Saiba mais sobre nós"
                >
                  <span className="text-[9px] md:text-[18px] sm:text-[20px] font-light mx-3 nirmala-font">Descubra como a VoltMAIS pode ajudar sua empresa</span>
                  <span className="w-8 h-8 bg-[#4ed44f] flex items-center justify-center rounded-full group-hover:bg-[#0055fe]">
                    <FaLongArrowAltRight className="text-[#0055fe] group-hover:text-white transition-colors duration-300" />
                  </span>
                </button>
              </div>
            </div>
          </section>
          <img
        src={nuvem}
        alt="Nuvem 1"
        className="absolute w-[800px] h-auto z-10 opacity-30"
        style={{ top: '40%', left: '15%' }}
      />
        </FadeInSection>
        <div className="h-[125px] w-[1px] bg-[#4ed44f] mb-10 mx-2"></div>
        <FadeInSection>
          <section className="py-16">
            <div className="container mx-auto px-4">
              <h2 className="text-7xl font-bold text-center mb-12 AcuminPro-font text-white">NOSSAS SOLUÇÕES</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-white">
                {['Gestão de Energia Renovável', 'Automação e Eficiência Elétrica', 'Manutenção e Monitoramento'].map((item, index) => (
                  <div key={index} className="bg-[#034cdf8c] p-6 rounded-lg shadow-md">
                    <h3 className="text-4xl font-bold mb-2 AcuminPro-font">{item}</h3>
                    <p className="text-2xl nirmala-font">{index === 0 ? 'Implementação e gerenciamento de fontes de energia limpa.' :
                      index === 1 ? 'Otimização de processos e redução do consumo energético.' :
                        'Serviços preventivos e preditivos para máxima performance.'}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </FadeInSection>
        <section
          className="py-60 w-full rounded-3xl relative overflow-hidden"
        >
          <div
            className="absolute inset-0 bg-cover bg-center opacity-50"
            style={{
              backgroundImage: `url(${green_bg})`,
            }}
          ></div>
          {/* Conteúdo Principal */}
          <div className="relative z-10 flex flex-col items-center justify-center w-full">
            <h2 className="text-5xl AcuminPro-font font-bold text-white texto-contorno text-center mt-12 drop-shadow-2xl transition-all duration-300 hover:text-[#3ca33c]">
              BENEFÍCIOS VoltMAIS
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-center w-full">
              {['Redução de Custos', 'Aumento da Competitividade', 'Sustentabilidade a Longo Prazo'].map((item, index) => (
                <div key={index} className="text-center text-white AcuminPro-font text-4xl mt-6">
                  <h3 className="text-2xl font-bold text-white drop-shadow-2xl shadow-black transition-all duration-300 hover:text-[#3ca33c]">
                    {item}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </section>
        <img
        src={nuvem}
        alt="Nuvem 1"
        className="absolute w-[800px] h-auto z-10 opacity-30"
        style={{ top: '75%', left: '-5%' }}
      />
        <FadeInSection>
          <section className="py-20 text-white">
            <div className="container mx-auto px-4 text-center">
              <h2 className="text-4xl font-bold mb-4">Transforme sua Indústria com a VoltMAIS</h2>
              <p className="text-2xl mb-8">Agende uma Consultoria Gratuita</p>
              <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white rounded-lg p-6 shadow-lg space-y-4">
                <input
                  type="text"
                  name="nome"
                  placeholder="Nome"
                  value={formData.nome}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="nome@dominio.com"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <input
                  type="tel"
                  name="telefone"
                  placeholder="Telefone"
                  value={formData.telefone}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  pattern="\(?\d{2}\)?[\s-]?\d{5}-?\d{4}"
                  title="O número de telefone deve estar no formato (11) 98765-4321 ou 11987654321"
                  required
                />
                <input
                  type="text"
                  name="empresa"
                  placeholder="Empresa"
                  value={formData.empresa}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="w-full bg-green-900 text-white py-3 rounded-lg font-semibold hover:bg-[#3ca33c] transition-all"
                >
                  Falar com um Especialista Agora
                </button>
              </form>
            </div>
          </section>
        </FadeInSection>
      </div>
      <WhatsAppButton />
      <Footer />
    </div>
  )
}

export default Industry
